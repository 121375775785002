// rename to multiPanelSlider
// allow clicking on other slides to make them active
/*
// usage
*/
import MouseDownEvent = JQuery.MouseDownEvent;
import SelectEvent = JQuery.SelectEvent;
import TouchStartEvent = JQuery.TouchStartEvent;
import TouchEndEvent = JQuery.TouchEndEvent;
import MouseUpEvent = JQuery.MouseUpEvent;

let ZIDXMultiPanelSliderOffset=0;
class ZIDXMultiPanelSliderOptions{
	arrSlide:string[]=[];
	selector:string="";
	pager:boolean=true;
	pagerStyle:string="zMultiPanelCircles"; // zMultiPanelSquares
	auto:boolean=true;
	timeout:number=5000;
	touchSwipe:boolean=true;
	nextPrevious:boolean=true;
	nextButton:string="&#10093;";
	previousButton:string="&#10092;";
	nextPreviousCenterClass="";
	slideWidthPercent:number=66.66;
	slideMinimumWidth:number=200;
	animationStyle:string="click"; // click or mouseMove
	mouseMoveMinVelocity:number=0;
	mouseMoveMaxVelocity:number=5;
	mouseMoveVelocityChangeSpeed:number=0.4;
	mouseMoveDeadZone:number=0.25;
}
class ZIDXMultiPanelSlider{
	options:ZIDXMultiPanelSliderOptions;
	sliderInterval=0;
	pager:JQuery=ZIDX.$;
	firstLoad=true;
	previousButton:JQuery=ZIDX.$;
	nextButton:JQuery=ZIDX.$;
	panelCount=1;
	middlePanelOffset=0;
	left=0;
	firstPercent=0;
	animating=false;
	currentSlideIndex=0;
	direction=0;
	slider:JQuery;
	arrPanelOrder:string[]=[];
	currentSlideWidthPercent:number;
	updateSlideWidthIntervalId=0;
	constructor(options:ZIDXMultiPanelSliderOptions){
		ZIDXMultiPanelSliderOffset++;
		let self=this;
		this.options=options;
		if(this.options.animationStyle=="mouseMove" && this.options.pager){
			throw('options.pager must be false when options.animationStyle=="mouseMove"');
		}
		this.slider=ZIDX.$(options.selector);
		this.currentSlideWidthPercent=Math.round(options.slideWidthPercent*100)/100;

		if(this.slider.length==0){
			console.log("ZIDXMultiPanelSlider: options.selector not found: "+options.selector);
			return;
		}
		if(options.arrSlide.length==0){
			console.log("ZIDXMultiPanelSlider: options.arrSlide has no slides");
			return;
		}
		if(options.nextPreviousCenterClass==""){
			options.nextPreviousCenterClass=options.selector+" .zidxMultiPanelSlide";
		}
		if(ZIDX.$(".zidxMultiPanelSliderBackground").length==0) {
			this.slider.append('<div class="zidxMultiPanelSliderBackground"><div class="zidxMultiPanelSlider"></div></div>');
		}
		this.updateSlideWidth();
		this.reloadSlides(false, 0);
		this.resizeSlider();
		ZIDX.$(window).on("clientresize resize", function(ev:any){
			self.resizeSlider();
		});
		this.runAnimation();

		setInterval(function(){
			if(ZIDX.windowSize.width<=992){
				self.currentVelocity=0;
				return;
			}
			if(options.animationStyle != "mouseMove"){
				return;
			}
			if(!self.mouseHasMoved){
				return;
			}
			let pos=ZIDX.getAbsPosition(self.slider[0]);

			let sliderWidth=pos.width;

			let percentMouseX=(ZIDX.mousePosition.x-pos.x)/sliderWidth;
			let velocityRange=options.mouseMoveMaxVelocity-options.mouseMoveMinVelocity;
			let newVelocity=0;
			// see if we're out of the dead zone
			let halfDeadZone=options.mouseMoveDeadZone/2;
			let liveZone=0.5-halfDeadZone;
			if(percentMouseX<liveZone){
				// left, negative
				newVelocity=((liveZone-percentMouseX)/liveZone)*velocityRange;
				//currentVelocity=((0.25-percentMouseX)/0.25)*velocityRange;
			}else if(percentMouseX>0.5+halfDeadZone){
				// right, positive
				newVelocity=-((percentMouseX-(0.5+halfDeadZone))/liveZone)*velocityRange;
				//currentVelocity=-((percentMouseX-0.75)/0.25)*velocityRange;
			}
			let reducedVelocityChange=(newVelocity-self.currentVelocity)*options.mouseMoveVelocityChangeSpeed;
			if(Math.abs(reducedVelocityChange)<=0.01 && Math.abs(self.currentVelocity)<=0.01){
				// force it to stop when its too slow
				reducedVelocityChange=0;
				self.currentVelocity=0;
			}else{
				//console.log("currentVelocity:"+currentVelocity+" newVelocity:"+newVelocity+" | reducedVelocityChange:"+reducedVelocityChange);
			}
			self.currentVelocity=self.currentVelocity+reducedVelocityChange;
			if(self.currentVelocity<0){
				self.currentVelocity=Math.max(-options.mouseMoveMaxVelocity, self.currentVelocity);
			}else if(self.currentVelocity>0){
				self.currentVelocity=Math.min(options.mouseMoveMaxVelocity, self.	currentVelocity);
			}
		}, 100);
	}
	reinit(){
		this.updateSlideWidth();
		this.reloadSlides(false, 0);
		this.resizeSlider();
	}

	updateSlideWidth(){
		let display=this.slider.css("display");
		let sliderPosition: { x: number, width: number, y: number, height: number};
		if(display == "" || display == "none"){
			this.slider.show();
			sliderPosition=ZIDX.getAbsPosition(this.slider[0]);
			this.slider.hide();
		}else{
			sliderPosition=ZIDX.getAbsPosition(this.slider[0]);
		}
		if(sliderPosition.width==0){
			return; // ignore when not visible
		}
		let slideWidth=Math.round(sliderPosition.width*(this.options.slideWidthPercent/100));
		//console.log("before currentSlideWidthPercent:"+currentSlideWidthPercent);
		if(slideWidth<this.options.slideMinimumWidth){
			this.currentSlideWidthPercent=Math.round((this.options.slideMinimumWidth/sliderPosition.width)*10000)/100;
		}
		let lastPanelCount=this.panelCount;
		this.panelCount=Math.round(100/this.currentSlideWidthPercent)+1;
		//console.log("fix panelCount:"+panelCount+" : "+Math.floor(panelCount/2));
		if(this.panelCount % 2 == 0){
			// force to odd number of panels
			this.panelCount++;
		}
		this.middlePanelOffset=Math.floor(this.panelCount/2);
		//console.log("panelCount:"+panelCount+" middlePanelOffset:"+middlePanelOffset);

		this.left=Math.round(((100-this.currentSlideWidthPercent)/2)*100)/100;
		this.firstPercent=Math.round((this.left-(this.currentSlideWidthPercent*this.middlePanelOffset))*100)/100;
		//console.log("firstPercent:"+firstPercent);
		//console.log(sliderPosition);
		//console.log("sliderPosition.width: "+sliderPosition.width+" panelCount:"+panelCount+" slideWidth:"+slideWidth+" options.slideMinimumWidth:"+options.slideMinimumWidth+"  currentSlideWidthPercent:"+currentSlideWidthPercent+" firstPercent:"+firstPercent+" left:"+left);
		// reposition all the slides - reload if panel count should change

		// clearInterval(this.updateSlideWidthIntervalId);
		let self=this;
		if(lastPanelCount==1){
			// on first load force it to not reload
			lastPanelCount=this.panelCount;
		}
		// if(self.panelCount != lastPanelCount){
		// 	lastPanelCount=self.panelCount;
		// 	// ZIDX.$(self.options.selector+' .zidxMultiPanelSlide').remove();
		// 	// self.reloadSlides(false, 0);
		// }
		// this.updateSlideWidthIntervalId=setInterval(function(){
		// 	// can't do this while animating is true
		// 	if(self.animating){
		// 		return;
		// 	}
		// 	clearInterval(self.updateSlideWidthIntervalId);
		// 	self.updateSlideWidthIntervalId=0;
		// 	if(self.panelCount != lastPanelCount){
		// 		console.log("panelCount changed to:"+self.panelCount);
		// 		lastPanelCount=self.panelCount;
		// 		self.reloadSlides(false, 0);
		// 	}
		// }, 100);
	}
	firstLoadInit(){
		this.firstLoad=false;
		if(this.options.nextPrevious){
			this.attachNextPreviousButtons();
		}
		if(this.options.touchSwipe){
			this.attachTouchSwipe();
		}

		if(this.options.arrSlide.length > 1){
			if ( this.options.pager ) {
				this.attachPager();
				this.setActivePager();
			}

			this.resetInterval();
		}
		let self=this;
		setInterval(function(){
			self.executeAnimationQueue();
		}, 300);
	}

	zDrag_mouseCoords(e:any) {
		let sl=document.documentElement.scrollLeft;
		let st=document.documentElement.scrollTop;
		if(typeof sl === "undefined") sl=document.body.scrollLeft;
		if(typeof st === "undefined") st=document.body.scrollTop;
		let xMousePosMax = window.innerWidth+window.pageXOffset;
		let yMousePosMax = window.innerHeight+window.pageYOffset;
		let xMousePos=0;
		let yMousePos=0;
		if (e.pageX){ xMousePos=e.pageX;
		}else if(e.clientX){ xMousePos=e.clientX + (sl);}
		if (e.pageY){ yMousePos=e.pageY;
		}else if (e.clientY){ yMousePos=e.clientY + (st);}
		return {x:xMousePos,y:yMousePos,pageWidth:xMousePosMax,pageHeight:yMousePosMax};
	}
	mouseHasMoved=false;
	dragging=false;
	attachTouchSwipe(){
		let self=this;

		this.slider.on( 'mousedown', function(this:any, event:MouseDownEvent ) {
			let mousePos = self.zDrag_mouseCoords(event.originalEvent);
			this.lastMousePositionY=mousePos.y; 
			this.lastMousePositionX=mousePos.x; 
			//theImageRotator.cancelAutoRotate();  
			return false;
		} );
		this.slider.on( 'mousemove', function(this:any,  event:any ) {
			if(typeof this.lastMousePositionY=="undefined"){
				return false;
			}
			this.mouseHasMoved=true;
			let mousePos = self.zDrag_mouseCoords(event.originalEvent);
			let differenceY=this.lastMousePositionY-mousePos.y;
			if(Math.abs(differenceY) < 50){
				event.preventDefault(); 
			}
			let differenceX=this.lastMousePositionX-mousePos.x;
			if(differenceX < 0){
				if(differenceX < -50){
					self.previous();
				}
			}else{
				if(differenceX > 50){
					self.next();
				}
			}
			// console.log("diff", differenceX, differenceY);
			if(differenceX!=0||differenceY!=0){
				self.dragging=true;
			}
			if(Math.abs(differenceX) > 50){
				this.lastMousePositionX=mousePos.x;
			}
			if(Math.abs(differenceY) < 50){
				return false;
			}else{
				return true;
			}
		} );
		this.slider.on( 'select', function(e:SelectEvent){
			e.preventDefault();
		});
		this.slider.on( 'mouseup mouseout', function(this:any, event:any ) {
			delete this.lastMousePositionY;
			delete this.lastMousePositionX;
			setTimeout(function(){
				self.dragging=false;
			},10);
			// return false;
		} ); 

		this.slider.on( 'touchstart', function(this:any, event:TouchStartEvent ) {
			this.lastTouchPosition=event.originalEvent;  
		} );
		this.slider.on( 'touchmove', function(this:any, event:any ) {
			if(typeof this.lastTouchPosition =="undefined" || typeof this.lastTouchPosition.touches == "undefined"){
				return;
			}
			let differenceY=this.lastTouchPosition.touches[0].pageY-event.originalEvent.touches[0].pageY;
			if(Math.abs(differenceY) < 30){
				event.preventDefault();
			}
			let differenceX=this.lastTouchPosition.touches[0].pageX-event.originalEvent.touches[0].pageX;
			if(differenceX < 0){
				if(differenceX < -30){
					self.previous();
				}
			}else{
				if(differenceX > 30){
					self.next();
				}
			}
			if(differenceX!=0||differenceY!=0){
				self.dragging=true;
			}
			if(Math.abs(differenceX) > 30){
				this.lastTouchPosition=event.originalEvent;
			}
			if(Math.abs(differenceY) < 30){
				return false; 
			}else{
				return true;
			}
		} );
		this.slider.on( 'touchend', function(this:any, event:TouchEndEvent ) {
			delete this.lastTouchPosition;
			setTimeout(function(){
				self.dragging=false;
			},10);
			// return false;
		} ); 
	}
	setActivePager(){
		if ( this.options.pager ) {
			ZIDX.$( 'span', this.pager ).removeClass( 'zidxMultiPanelActive' );
			ZIDX.$( 'span[data-slide-index="' + this.currentSlideIndex + '"]', this.pager ).addClass( 'zidxMultiPanelActive' );
		}
		ZIDX.$(this.options.selector+" .zidxMultiPanelSlide").removeClass("zidxMultiPanelActive");
		ZIDX.$(this.options.selector+" ."+this.arrPanelOrder[this.middlePanelOffset]).addClass("zidxMultiPanelActive");
	}


	resetInterval() {
		clearInterval( this.sliderInterval );
		if ( this.options.auto ) {
			let self=this;
			this.sliderInterval = setInterval( function() {
				self.animateToSlide(self.getSlideIndex(self.currentSlideIndex, 1), 1);
			}, this.options.timeout );
		}
	}
	attachNextPreviousButtons() {
		let self=this;
		this.slider.append( '<a href="#" class="zidxMultiPanelSliderPreviousButton">' + this.options.previousButton + '</a><a href="#" class="zidxMultiPanelSliderNextButton">' + this.options.nextButton + '</a>' );

		this.previousButton = ZIDX.$( '.zidxMultiPanelSliderPreviousButton', this.slider );
		this.nextButton     = ZIDX.$( '.zidxMultiPanelSliderNextButton', this.slider );

		this.previousButton.on( 'keyup', function(this:any, event:any ) {
			if(event.which==13){
				self.previous();
			}
		});
		this.nextButton.on( 'keyup', function(this:any, event:any ) {
			if(event.which==13){
				self.next();
			}
		});

		this.previousButton.on( 'mousedown touchstart', function(this:any, event:any ) {
			// event.preventDefault();
			this.clickTouchStart=true;
		} );
		this.previousButton.on( 'touchstart', function(this:any, event:TouchStartEvent ) {
			// event.preventDefault();
			this.clickTouchStart=true;
		} );
		this.previousButton.on( 'click touchend', function(this:any, event:any ) {
			if(typeof this.clickTouchStart == "undefined" || !this.clickTouchStart){
				return false;
			}
			this.clickTouchStart=false;
			self.previous();
		});
		this.nextButton.on( 'mousedown touchstart', function(this:any, event:any ) {
			// event.preventDefault();
			this.clickTouchStart=true;
		} );
		this.nextButton.on( 'click touchend', function(this:any, event:any ) {
			event.preventDefault();
			if(typeof this.clickTouchStart == "undefined" || !this.clickTouchStart){
				return false;
			}
			this.clickTouchStart=false;
			self.next();
		});
	}
	attachPager() {
		let self=this;
		if(ZIDX.$(".zidxMultiPanelSliderPager."+this.options.pagerStyle).length==0) {
			this.slider.append('<div class="zidxMultiPanelSliderPager ' + this.options.pagerStyle + '"></div>');
		}

		this.pager = ZIDX.$( '.zidxMultiPanelSliderPager', this.slider );

		for(let i=0;i<this.options.arrSlide.length;i++){
			this.pager.append( '<span data-slide-index="' + i + '"></span>' );
		}

		this.pager.on( 'mousedown touchstart', 'span', function(this:any, event:any ) {
			if((event.type == "mousedown") && event.which!=1){
				return;
			}
			this.clickTouchStart=true;
		});
		this.pager.on( 'mouseup touchend', 'span', function(this:any, event:any ) {
			event.preventDefault();
			if((event.type == "mouseup") && event.which!=1){
				return false;
			}
			if(typeof this.clickTouchStart == "undefined" || !this.clickTouchStart){
				return false;
			} 
			this.clickTouchStart=false;
			let pagerSlideIndex = parseInt(ZIDX.$( this ).attr( 'data-slide-index' ));

			if ( pagerSlideIndex == this.currentSlideIndex ) {
				// Don't switch slides if we clicked on the active slide pager.
				return false;
			} 
			if ( this.options.auto ) {
				this.resetInterval();
			}  
			self.queueAnimateToSlide(pagerSlideIndex);
			return false;
		} ); 
	}
	resizeSlider(){
		//d.slideWidth=ZIDX.$(options.selector+" .zidxMultiPanelSlide > div").width();
		let sliderPosition=ZIDX.getAbsPosition(this.slider[0]);
		if(sliderPosition.width==0){
			return;
		}
		this.updateSlideWidth();
		let sliderWidth=this.slider.width();
		//console.log(d);
		let maxHeight=0;
		ZIDX.$(this.options.selector+" .zidxMultiPanelSlide").each(function(this:HTMLElement){
			maxHeight=Math.max(maxHeight, ZIDX.getAbsPosition(this).height);
		});
		//console.log("maxHeight:"+maxHeight);
		ZIDX.$(this.options.selector+" .zidxMultiPanelSlider").height(maxHeight);


		if(!this.firstLoad && this.options.nextPrevious){
			let centerClassHeight=0;
			ZIDX.$(this.options.nextPreviousCenterClass).each(function(this:HTMLElement){
				centerClassHeight=Math.max(centerClassHeight, ZIDX.getAbsPosition(this).height);
			});
			//console.log("centerClassHeight:"+centerClassHeight+" length:"+ZIDX.$(options.nextPreviousCenterClass).length);
			this.nextButton.css("top", Math.round((centerClassHeight/2))+"px");
			this.previousButton.css("top", Math.round((centerClassHeight/2))+"px");
		}  
	}
	getSlideIndex(currentSlideIndex:number, offset:number){
		let slideIndex=currentSlideIndex+offset;
		if(slideIndex >= this.options.arrSlide.length){
			let remainder=slideIndex-(this.options.arrSlide.length);
			if(remainder>=this.options.arrSlide.length){
				return this.options.arrSlide.length-1;
			}else{
				return remainder;
			}
		}else if(slideIndex < 0){ 
			let remainder=this.options.arrSlide.length-Math.abs(slideIndex);
			if(remainder < 0){
				return this.options.arrSlide.length-1;
			}else{
				return remainder;
			}
		}else{
			return slideIndex;
		}
	}
	reloadSlides(animate:boolean, direction:number){
		ZIDX.$(this.options.selector+' .zidxMultiPanelSlide').remove();
		let slideIndex=this.currentSlideIndex;
		let arrHTML=[];
		let arrPanelOrderNew=[];
		// console.log('reloadSlides: '+this.options.selector+' panelCount:'+this.panelCount);
		ZIDX.$(this.options.selector+" .multiPanelSlider").empty();
		for(let i=0;i<this.panelCount;i++){
			let loadSlideIndex=this.getSlideIndex(slideIndex, i-this.middlePanelOffset );
			// console.log("reloadSlides loadSlideIndex: "+loadSlideIndex+":"+typeof slideIndex);
			let slide=this.options.arrSlide[loadSlideIndex];
			arrHTML.push('<div class="zidxMultiPanelSlide zidxMultiPanelSlide'+(i+1)+' ');
			if(i == this.middlePanelOffset){
				arrHTML.push('zidxMultiPanelActive');
			}
			this.left=Math.round(((100-this.currentSlideWidthPercent)/2)*100)/100;
			arrHTML.push('" style="position:absolute; z-index:'+(ZIDXMultiPanelSliderOffset+1)+'; width:'+this.currentSlideWidthPercent+'%; left:'+this.left+'%;">'+slide+'</div>');
			arrPanelOrderNew.push("zidxMultiPanelSlide"+(i+1));
		}
		//console.log(arrHTML);
		ZIDX.$(this.options.selector+" .zidxMultiPanelSlider").append(arrHTML.join(""));
		let loadCount=0;
		let $slideImages=ZIDX.$(this.options.selector+' .zidxMultiPanelSlide img');
		let self=this;
		if($slideImages.length==0) {
			ZIDX.$(this.options.selector).show();
			if(this.firstLoad) {
				this.firstLoadInit();
				this.resizeSlider();
			}
		}else{
			let totalImages=$slideImages.length;
			setInterval(function(){
				$slideImages.each(function(this:any){
					if(this.complete && typeof this.slideImageLoaded == "undefined"){
						loadCount++;
						this.slideImageLoaded=true;
						if(loadCount==totalImages){
							ZIDX.$(self.options.selector).show();
							if(self.firstLoad){
								self.firstLoadInit();
								self.resizeSlider();
							}
							//resizeSlider();
						}
					}
				});
			}, 100);
			$slideImages.on("load", function(e:any){
				loadCount++;
				if(loadCount==totalImages){
					//console.log(options.selector+' loadCount:'+loadCount+'== totalImages:'+totalImages);
					ZIDX.$(self.options.selector).show();
					if(self.firstLoad){
						self.firstLoadInit();
						self.resizeSlider();
					}
					//resizeSlider();
				}
			});
		}

		for(let i=0;i<=arrPanelOrderNew.length-1;i++){
			let m=arrPanelOrderNew[i];
			let left=this.firstPercent+((i)*this.currentSlideWidthPercent);
			if(animate){  
				ZIDX.$(this.options.selector+" ."+m).css({
					"left":(Math.round((left+(this.panelCount*this.currentSlideWidthPercent*direction))*100)/100)+"%"
				}).animate({
					"left": (left)+"%"
				}, 'slow','easeInExpo', function(){
					self.animating=false;
				}); 
			}else{
				ZIDX.$(this.options.selector+" ."+m).css({
					"left": (left)+"%"
				}); 
			}
		}
		this.arrPanelOrder=arrPanelOrderNew;
		this.setupPanelEvents();
	}

	next(){
		this.animateToSlide(this.getSlideIndex(this.currentSlideIndex, 1), 1);
		this.options.auto=false;
		this.resetInterval();
	}
	previous(){
		this.animateToSlide(this.getSlideIndex(this.currentSlideIndex, -1), -1);
		this.options.auto=false;
		this.resetInterval();
	}
	arrAnimateQueue:number[]=[];
	queueAnimateToSlide(slideIndex:number){
		this.options.auto=false;
		this.arrAnimateQueue.push(slideIndex);
		//console.log(slideIndex);
	}
	executeAnimationQueue(){
		if(!this.animating && this.arrAnimateQueue.length){
			let slideIndex=this.arrAnimateQueue.pop()!;
			let offset=slideIndex-this.currentSlideIndex;
			this.animateToSlide(this.getSlideIndex(this.currentSlideIndex, offset), offset);
			this.arrAnimateQueue=[];
		}
	};

	currentVelocity=0;
	panelLeftCache:any={};
	panelTotalOffset=0;
	runAnimation(){
		if(this.options.animationStyle != "mouseMove"){
			return;
		}
		if(ZIDX.windowSize.width<=992){ 
			window.requestAnimationFrame(this.runAnimation);
			return;
		}
		this.animating=false;

		this.panelTotalOffset+=this.currentVelocity;
		if(Math.abs(this.panelTotalOffset) >= this.currentSlideWidthPercent){
			this.panelLeftCache=[];
			let slideIndex=this.currentSlideIndex;
			if(this.panelTotalOffset>0){
				let firstPanel=this.arrPanelOrder[0];
				let lastPanel=this.arrPanelOrder.pop()!;
				this.arrPanelOrder.unshift(lastPanel);
				let loadSlideIndex=this.getSlideIndex(slideIndex, -this.middlePanelOffset-1);
				let slide=this.options.arrSlide[loadSlideIndex];
				ZIDX.$(this.options.selector+" ."+lastPanel).html(slide);
				this.currentSlideIndex=this.getSlideIndex(slideIndex, -1);
			}else if(this.panelTotalOffset<0){
				let loadSlideIndex=this.getSlideIndex(slideIndex, this.middlePanelOffset+1);
				let firstPanel=this.arrPanelOrder.shift()!;
				this.arrPanelOrder.push(firstPanel);
				let slide=this.options.arrSlide[loadSlideIndex];
				ZIDX.$(this.options.selector+" ."+firstPanel).html(slide);
				this.currentSlideIndex=this.getSlideIndex(slideIndex, 1);
			}
			this.panelTotalOffset=0;
		}
		for(let i=0;i<this.arrPanelOrder.length;i++){
			let panel=ZIDX.$(this.options.selector+" ."+this.arrPanelOrder[i])[0];
			if(typeof this.panelLeftCache[this.arrPanelOrder[i]]=="undefined"){
				let left=this.firstPercent+((i)*this.currentSlideWidthPercent);
				this.panelLeftCache[this.arrPanelOrder[i]]=left;
			}
			this.panelLeftCache[this.arrPanelOrder[i]]+=this.currentVelocity;
			let newLeft=(Math.round(this.panelLeftCache[this.arrPanelOrder[i]]*100)/100)+"%";
			panel.style.left=newLeft;
		}
		this.setupPanelEvents();

		window.requestAnimationFrame(this.runAnimation);
	};
	setupPanelEvents(){
		let self=this;
		ZIDX.$(this.options.selector+" .zidxMultiPanelSlide a").off("click");
		ZIDX.$(this.options.selector+" .zidxMultiPanelSlide a").on("click", function(this:any, event:any){
			if(self.dragging){
				event.preventDefault();
				return false;
			}
		});
	}

	animateToSlide(slideIndex:number, offset:number){
		if(this.options.animationStyle=="mouseMove"){
			if(ZIDX.windowSize.width>992){
				return;
			}
		}
		if(this.animating){
			return;
		}
		//console.log("animateToSlide:  current:"+currentSlideIndex+" next:"+slideIndex); 
		this.animating=true;
		if(offset==0){
			// do nothing
			//console.log('doNothingToSlides');
			this.animating=false;
			return;
		}else if(offset==-1){
			if(this.direction==0){
				this.direction=-1;
			}
			// previous
			//console.log('previousSlide');

			// change panel order
			let firstPanel=this.arrPanelOrder[0];
			let lastPanel=this.arrPanelOrder.pop()!;
			this.arrPanelOrder.unshift(lastPanel);
			ZIDX.$("."+lastPanel).insertBefore("."+firstPanel); 
 
			//console.log(arrPanelOrder);
 
			// get the photo index to load
			let loadSlideIndex=this.getSlideIndex(slideIndex, -this.middlePanelOffset);

			//console.log("previous:"+loadSlideIndex);
			let slide=this.options.arrSlide[loadSlideIndex];
			ZIDX.$(this.options.selector+" ."+lastPanel).html(slide);
			//console.log('loadSlideIndex:'+loadSlideIndex);
 
			//let firstPercent=-116.66;
			let self=this;
			for(let i=0;i<this.arrPanelOrder.length;i++){
				let left=this.firstPercent+((i)*this.currentSlideWidthPercent);
				ZIDX.$(this.options.selector+" ."+this.arrPanelOrder[i]).css({
					"left":(Math.round((left-this.currentSlideWidthPercent)*100)/100)+"%"
				}).animate({
					"left": (left)+"%"
				}, 'fast','easeInExpo', function(){
					self.animating=false;
				});
			} 

		}else if(offset==1){ 
			// next
			//console.log('nextSlide');

			// get the photo index to load
			let loadSlideIndex=this.getSlideIndex(slideIndex, this.middlePanelOffset);

			//console.log("next:"+loadSlideIndex);
			// change panel order
			let lastPanel=this.arrPanelOrder[this.arrPanelOrder.length-1];
			let firstPanel=this.arrPanelOrder.shift()!;
			this.arrPanelOrder.push(firstPanel);

			// get the photo index to load
			loadSlideIndex=this.getSlideIndex(slideIndex, this.middlePanelOffset);

			let slide=this.options.arrSlide[loadSlideIndex];
			ZIDX.$(this.options.selector+" ."+firstPanel).html(slide);

			//firstPercent=-116.66;

			let self=this;
			for(let i=0;i<this.arrPanelOrder.length;i++){
				let left=this.firstPercent+((i)*this.currentSlideWidthPercent);
				ZIDX.$(this.options.selector+" ."+this.arrPanelOrder[i]).css({
					"left":(Math.round((left+this.currentSlideWidthPercent)*100)/100)+"%"
				}).animate({
					"left": (left)+"%"
				}, 'fast','easeInExpo', function(){
					self.animating=false;
				});
			} 
		}else{
			// reload all slides
			//console.log('reload all slides');  
			// animate all the existing slides offscreen
			//firstPercent=-116.66;
			let direction:number;
			if(offset>0){
				direction=1;
			}else{
				direction=-1;
			}
			for(let i=0;i<=this.arrPanelOrder.length-1;i++){
				let left=this.firstPercent+((i)*this.currentSlideWidthPercent);
				ZIDX.$(this.options.selector+" ."+this.arrPanelOrder[i]).animate({
					"left": (left-(this.currentSlideWidthPercent*this.panelCount*direction))+"%"
				}, 'slow','easeInExpo', function(this:any){
					this.animating=false;
					ZIDX.$(this).remove();
				});
			}
			this.currentSlideIndex=slideIndex;
			this.reloadSlides(true, direction);
		}
		this.setupPanelEvents();
		this.currentSlideIndex=slideIndex;
		this.setActivePager();
		this.resetInterval();
	}


}